﻿@import "../ui-variables";

body {
    &.win-accent {
        .accent-mixin(@accents, "body");
    }
}


div,
pre,
section,
footer,
header {
    &.win-accent {
        .accent-mixin(@accents, "container");
    }

    &.emphasize {
        background-color: @emphasize-color;
    }
}


.win-type-header,
.win-type-subheader,
.win-type-title,
.win-type-subtitle,
.win-type-jumbo,
.win-type-base,
.win-type-body,
.win-type-lead,
.win-type-caption,
.win-h1,
.win-h2,
.win-h3,
.win-h4,
.win-h5,
.win-h6,
.win-button,
.win-dropdown,
.win-textbox,
.win-link,
.win-textarea,
[class*="win-icon-"] {

    &.win-accent {
        .accent-mixin(@accents, "text");
    }

    &.emphasize {
        font-weight: bold !important;
    }
}






.accent-mixin(@list, @type) {

    .loop(@i) when (@i > 0) {
        .action(@i);
        .loop(@i - 1);
    }

    .action(@index) {
        @key: extract(extract(@list, @index), 1);
        @value: extract(extract(@list, @index), 2);
        @scrollTrackFadeAmount: 40%;
        @scrollLightenAmount: 10%;
        @scrollHoverLightenAmount: 20%;
        @scrollActiveLightenAmount: 5%;
        @scrollButtonLightenAmount: 15%;

        &-@{key} when (@type = "body") {
            // this should allow us to express the accent color once i.e ".win-accent-amber"
            // and then use ".win-accent" for all children that way we only need to set the actual
            // color once.
            div,
            pre,
            footer,
            header {

                &.win-accent-scroll {
                    scrollbar-base-color: lighten(@value, @scrollLightenAmount);
                    scrollbar-face-color: lighten(@value, @scrollLightenAmount);
                    scrollbar-track-color: fade(white, @scrollTrackFadeAmount);
                    scrollbar-arrow-color: lighten(@value, @scrollLightenAmount);
                    scrollbar-shadow-color: lighten(@value, @scrollLightenAmount);
                    -ms-interpolation-mode: nearest-neighbor;
                    scrollbar-color: lighten(@value, @scrollLightenAmount) fade(white, @scrollTrackFadeAmount); //Firefox
                }
                                                                // \/ allows scrolltrack to be applied to scroll containers in winjs
                &.win-accent-scroll::-webkit-scrollbar-track, &.win-accent-scroll div::-webkit-scrollbar-track {
                    background-color: fade(white, @scrollTrackFadeAmount);
                }

                &::-webkit-scrollbar {
                    width: auto;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: lighten(@value, @scrollLightenAmount);
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: lighten(@value, @scrollHoverLightenAmount);
                }

                &::-webkit-scrollbar-thumb:active {
                    background-color: lighten(@value, @scrollActiveLightenAmount);
                }

                &::-webkit-scrollbar-button {
                    border-style: solid;
                    border-color: transparent;
                }
                /* Up */
                &::-webkit-scrollbar-button:vertical:decrement {
                    border-width: 0 8px 15px 8px;
                    border-bottom-color: lighten(@value, @scrollButtonLightenAmount);
                }

                &::-webkit-scrollbar-button:vertical:decrement:hover {
                    border-bottom-color: lighten(@value, @scrollHoverLightenAmount);
                }
                /* Down */
                &::-webkit-scrollbar-button:vertical:increment {
                    border-width: 15px 8px 0 8px;
                    border-top-color: lighten(@value, @scrollButtonLightenAmount);
                }

                &::-webkit-scrollbar-button:vertical:increment:hover {
                    border-top-color: lighten(@value, @scrollHoverLightenAmount);
                }
                /* Left */
                &::-webkit-scrollbar-button:horizontal:decrement {
                    border-width: 8px 15px 8px 0;
                    border-right-color: lighten(@value, @scrollButtonLightenAmount);
                }

                &::-webkit-scrollbar-button:horizontal:decrement:hover {
                    border-right-color: lighten(@value, @scrollHoverLightenAmount);
                }
                /* Right */
                &::-webkit-scrollbar-button:horizontal:increment {
                    border-width: 8px 0 8px 15px;
                    border-left-color: lighten(@value, @scrollButtonLightenAmount);
                }

                &::-webkit-scrollbar-button:horizontal:increment:hover {
                    border-left-color: lighten(@value, @scrollHoverLightenAmount);
                }


                &::-webkit-scrollbar-track {
                    background-color: fade(@value, @scrollTrackFadeAmount);
                }

                &.win-accent {
                    background-color: @value;
                    background-color: @value;
                    scrollbar-base-color: lighten(@value, @scrollLightenAmount);
                    scrollbar-face-color: lighten(@value, @scrollLightenAmount);
                    scrollbar-track-color: fade(@value, @scrollTrackFadeAmount);
                    scrollbar-arrow-color: lighten(@value, @scrollLightenAmount);
                    scrollbar-shadow-color: lighten(@value, @scrollLightenAmount);
                    -ms-interpolation-mode: nearest-neighbor;
                    scrollbar-color: lighten(@value, @scrollLightenAmount) fade(@value, @scrollTrackFadeAmount); //Firefox
                    .win-type-header,
                    .win-type-subheader,
                    .win-type-title,
                    .win-type-subtitle,
                    .win-type-jumbo,
                    .win-type-base,
                    .win-type-body,
                    .win-type-lead,
                    .win-type-caption,
                    .win-h1,
                    .win-h2,
                    .win-h3,
                    .win-h4,
                    .win-h5,
                    .win-h6,
                    .win-button,
                    //.win-dropdown,
                    //.win-textbox,
                    .win-link,
                    //.win-textarea,
                    [class*="win-icon-"] {
                        color: white;
                    }
                }
            }


            .win-type-header,
            .win-type-subheader,
            .win-type-title,
            .win-type-subtitle,
            .win-type-jumbo,
            .win-type-base,
            .win-type-body,
            .win-type-lead,
            .win-type-caption,
            .win-h1,
            .win-h2,
            .win-h3,
            .win-h4,
            .win-h5,
            .win-h6,
            .win-button,
            //.win-dropdown,
            //.win-textbox,
            .win-link,
            //.win-textarea,
            [class*="win-icon-"] {
                &.win-accent {
                    color: @value;
                }
            }
        }

        &-@{key} when (@type = "container") {
            background-color: @value;

            .win-type-header,
            .win-type-subheader,
            .win-type-title,
            .win-type-subtitle,
            .win-type-jumbo,
            .win-type-base,
            .win-type-body,
            .win-type-lead,
            .win-type-caption,
            .win-h1,
            .win-h2,
            .win-h3,
            .win-h4,
            .win-h5,
            .win-h6,
            .win-button,
            //.win-dropdown,
            //.win-textbox,
            .win-link,
            //.win-textarea,
            [class*="win-icon-"] {
                color: white;
            }
        }

        &-@{key} when (@type = "text") {
            color: @value;
        }
    }
    // start the loop
    .loop(length(@list));
}




// overrides for pivot control ( might want to be moved somewhere else )
.win-accent .win-pivot button.win-pivot-header {
    color: rgba(255, 255, 255, 0.6);
}

.win-accent .win-pivot button.win-pivot-header:hover {
    color: rgba(255, 255, 255, 0.8) !important;
}

.win-accent .win-pivot button.win-pivot-header-selected.win-pivot-header {
    color: rgb(255, 255, 255);
}
